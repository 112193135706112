.image-block {
  outline: 0px solid transparent;
  display: flex;
  flex-direction: column;
  padding: 0;
  outline: 1px solid var(--gray4);
  margin: 16px 0;
  border-radius: 4px;
}

.image-block.height-placeholder {
  padding-top: 56.25% !important;
  border: 3px solid #dcdcdc;
  border-radius: 5px;
}

.image-block img {
  background-color: #f7f7f9;
  border-radius: 4px 4px 0 0;
  object-fit: contain;
  width: 100%;
  max-height: 400px;
  position: relative;
}

.shared-note-focused-img img {
  border: 3px solid var(--blue8);
}

#checkmarkWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 0px;
  bottom: -17px;
  display: flex;
  gap: 3px;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 3;
  stroke-miterlimit: 10;
  stroke: var(--blue8);
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  stroke-width: 3;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px var(--blue8);
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.checkmark__statement {
  color: var(--blue8);
  font-size: 1.1rem !important;
  font-weight: 700;
  display: flex;
  align-items: center;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px var(--blue8);
  }
}

.upload-loading-statement {
  font-size: 1.1rem !important;
  color: var(--gray13);
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -webkit-user-drag: none;
}

.image-block .img-upload-loader-wrapper {
  position: absolute;
  right: 0px;
  bottom: -17px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 3px;
}

@media print {
  .image-block img {
    max-height: unset;
    border: unset;
    background: unset;
    max-height: 150mm;
  }

  @media (orientation: landscape) {
    .image-block img {
      max-height: 180mm;
    }
  }
}

.spinner-border-sm {
  width: 11px;
  height: 11px;
  color: var(--gray9);
  border-width: 0.15em;
}

.image-panel {
  width: 100%;
  height: 44px;
  border-top: 1px solid #e9ecef;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: auto;
}

/* hide panel on print media */
@media print {
  .image-panel {
    display: none;
  }
}

.panel-buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.image-panel__buttons {
  display: flex;
  align-items: center;
  /* margin-right: 16px; */
  gap: 4px;
}

.image-panel__button {
  /* s of reset button */
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;
  /* e of reset button  */
  padding: 4px;
  border-radius: 8px;
}

.image-panel__button:hover {
  background: #e9ecef;
}

.image-panel__button:active {
  background: #f1f3f5;
}

.image-panel__button:focus {
  outline-style: none;
}

/* on mobile phone */
@media (pointer: none), (pointer: coarse) {
  .image-panel__button:hover,
  .image-panel__button:active {
    background: #f1f3f5;
  }
  .image-panel__button:focus {
    outline-style: none;
    background: inherit;
  }
}

.image-panel__button.last {
  position: relative;
  margin-left: 24px;
}

.image-panel.smallest .image-panel__button.last {
  margin-left: 4px;
}

.image-panel__button.crop {
  position: relative;
  margin-left: 24px;
}
.image-panel.smallest .image-panel__button.crop {
  margin-left: 4px;
}

.image-panel__button.crop:before {
  content: "";
  background: #dee2e6;
  position: absolute;
  width: 1px;
  height: 20px;
  left: -13px;
  top: 6px;
  pointer-events: none;
}

.image-panel.smallest .image-panel__button.crop:before {
  display: none;
  left: -6px;
}
.image-panel__button.last:before {
  content: "";
  background: #dee2e6;
  position: absolute;
  width: 1px;
  height: 20px;
  left: -13px;
  top: 6px;
  pointer-events: none;
}

.image-panel.smallest .image-panel__button.last:before {
  display: none;
  left: -6px;
}

.image-panel__button.last svg path {
  fill: #868e96;
}

.image-panel__button.last:hover {
  background-color: rgba(249, 61, 63, 0.12);
}

.image-panel__button.last:active {
  background-color: rgba(249, 61, 63, 0.06);
}

.image-panel__button.last:hover svg path {
  fill: #f93d3f;
}

.image-panel__button.last:active svg path {
  fill: #f93d3f;
}

/* on mobile phone */
@media (pointer: none), (pointer: coarse) {
  .image-panel__button.last:hover,
  .image-panel__button.last:active {
    background-color: rgba(249, 61, 63, 0.06);
  }

  .image-panel__button.last:hover svg path,
  .image-panel__button.last:active svg path {
    fill: #f93d3f;
  }
}

.image-panel__timestamp-button {
  /* s of reset button */
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;
  /* e of reset button  */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 6px;
  margin: 2px 4px;
  background: #e7f5ff;
  border-radius: 4px;
}

.image-panel__timestamp-button:focus {
  outline-style: none;
}

.image-panel__timestamp-button:hover {
  background: #afd8fb;
  outline-style: none;
}

.image-panel__timestamp-button:active {
  background: #d0ebff;
  outline-style: none;
}

/* on mobile phone */
@media (pointer: none), (pointer: coarse) {
  .image-panel__timestamp-button:hover,
  .image-panel__timestamp-button:active {
    background: #f1f3f5;
  }
  .image-panel__timestamp-button:focus {
    outline-style: none;
    background: #e7f5ff;
  }
}

.image-panel__play-icon.mobile {
  margin-right: 2px;
}

.image-panel__icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
}

.image-panel__timestamp-button svg {
  height: 16px;
  width: 16px;
}

.image-panel__text {
  font-family: "SlidTextStyle", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  font-style: normal;
  line-height: 1.8rem;
  display: flex;
  gap: 2px;
  margin-left: 4px;
}

.image-panel__text-info {
  font-size: 1.3rem !important;
  font-weight: 400;
  color: #495057;
}

.image-panel__text-info.kor {
  order: 1;
}

/* on small resize */
.image-panel.small .image-panel__text-info {
  display: none;
}

.image-panel__text-time {
  font-size: 1.3rem !important;
  font-weight: 700;
  color: #343a40;
}

.image-panel.slim .image-panel__text {
  display: none;
}

/* for image block tooltip */
.image-panel__tooltip-title {
  display: block;
  font-weight: 700;
  color: var(--gray3);
  font-size: 1.2rem;
}

.image-panel__tooltip-shortcut {
  display: block;
  color: var(--gray7);
  font-size: 1.2rem;
}
